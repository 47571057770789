import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
   
  Select,
} from "antd";
import axios from "axios";
import config from "../../../../Config";
import { useNavigate } from "react-router-dom";

import Cropper from "react-cropper";


const DocPassport = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const { Text, Link, Title } = Typography;


    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [Email, setEmail] = useState(localStorage.getItem("Email_Address"));
    const [kyc, setkyc] = useState({});
    const [imageSelected, setImageSelected] = useState(false);
    const [validated, setValidated] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState();
    const [loading, setLoading] = useState(false);
    const [imageCropped, setImageCropped] = useState(false);
    const MAX_IMAGE_SIZE = 4 * 1024 * 1024; // 2MB in bytes
    let navigate = useNavigate();

    useEffect(() => {
  document.title= "KYC Passport Details"

        const dashboardParms = {
            AccessKey: AccessKey,
            UserID: UserID,
            KYCStatus: "Not Submitted"
        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'MemberKYC/GetKYCByMemberIDStatus',
            headers: {
                'Content-Type': 'application/json'
            },
            data: dashboardParms
        };

        axios(api_config)
            .then(function (response) {

                ////console.log(response.data);
                if (response.data.status_code == 1) {

                    setkyc(response.data);


                }


            })
            .catch(function (error) {
                // toast.error('Network Error..', {
                //     position: toast.POSITION.TOP_RIGHT,
                   
                // });
            });
    }, []);
    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setImageSelected(false)
            messageApi.open({
                type: "error",
                content: "Image size must be less than 4 MB",
              });
            // toast.error("Image size must be less than 2 MB", {
            //     position: toast.POSITION.TOP_RIGHT,
            // });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        setLoading(true);
        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);

            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                Passport: cleanerBase64
            };

            // //console.log(data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'MemberKYC/UpdatePassportKYC',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    
                    if (response.data.status_code == 1) {
                        messageApi.open({
                            type: "success",
                            content:"KYC submitted successfully",
                          });
                       
                        navigate('/member/kyc/submitted');
                    }
                    else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key."
                        || response.data.status_code === 0 && response.data.status_message === "Invalid Request Parameters") {
                        navigate("/login")
                    }
                    else {
                        messageApi.open({
                            type: "error",
                            content: response.data.status_message,
                          });
                       
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    messageApi.open({
                        type: "error",
                        content: 'Invalid Format',
                      });
                   
                });
        }
    };



    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDrop = (event) => {

        event.preventDefault();
        setImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setImageSelected(false)
                messageApi.open({
                    type: "error",
                    content: "Image size must be less than 4 MB",
                  });
                // toast.error("Image size must be less than 2 MB", {
                //     position: toast.POSITION.TOP_RIGHT,
                // });
                return;
            }

            // //console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                if (cropper) {
                    cropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };




  return (
  <>
    {contextHolder}

  
  <Row gutter={[24, 24]}>
        <Col md={20} offset={2} xs={24}>
          <Card title="Take photos of your document or upload them" bordered={true}>
            <Title style={{margin:"0px"}} level={5} >Upload the first page of your Passport. A third-party certified provider will check your identity so you'll be able to participate in the Towergear affiliate program</Title>
             <br/>
             <Title level={3} >Front Side </Title>
             <Row>

                <Col  md={10} offset={2} xs={24}>
                <div className="kycright">
                            <div className="kyc_content">
                                <div className="card-gradient">
                                    <div className="card-inner">
                                        <div className="scroll_content form_default">
                                           
                                            <div className="row">
                                                <div>
                                                   
                                                    {
                                                        kyc.DocumentType == "Passport" &&
                                                        <div style={{marginBottom:"4px"}}>
                                                            <img style={{ height: "100px" }} src={config.img_path + kyc.Passport} />
                                                        </div>
                                                    }
                                                    <div className="card-gradient">
                                                        <div className="card-inner" style={{ padding: "1px" }}>
                                                            <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                                                id="drop-area"
                                                                onDragOver={handleDragOver}
                                                                onDrop={handleDrop}
                                                            >
                                                                {
                                                                    imageSelected ?
                                                                        <>
                                                                            <Cropper
                                                                                zoomTo={0.5}
                                                                                // aspectRatio={8 / 8}
                                                                                preview=".img-preview"
                                                                                src={image}
                                                                                dragMode="move"
                                                                                viewMode={3}
                                                                                minCropBoxHeight={10}
                                                                                minCropBoxWidth={10}
                                                                                background={false}
                                                                                responsive={true}
                                                                                autoCropArea={1}
                                                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                                onInitialized={(instance) => {
                                                                                    setCropper(instance);
                                                                                }}
                                                                                guides={true}
                                                                            />
                                                                            <div  >
                                                                                <label htmlFor="ChoosePhoto1">
                                                                                    <span htmlFor="ChoosePhoto1" type="button" className="button button-outline-primary" style={{marginTop:"10px"}}>Re Upload</span>
                                                                                    <Input style={{position:"absolute",opacity:"0"}} id="ChoosePhoto1" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                                                </label>
                                                                            </div>
                                                                        </>

                                                                        :
                                                                        <>
                                                                            <label htmlFor="ChoosePhoto">
                                                                                <h4>Drag and drop your document</h4>
                                                                                <p>Max size 4 MB. Files allowed: JPG, PNG</p>
                                                                                <span htmlFor="ChoosePhoto" type="button" className="button button-outline-primary">Browse File</span>
                                                                                {/* <input id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} /> */}
                                                                                <Input style={{position:"absolute",opacity:"0"}} id="ChoosePhoto" accept="image/jpeg,image/png" type="file" onChange={onChange} />

                                                                            </label>

                                                                        </>

                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                </Col>
             </Row>
             <div style={{textAlign:"end",marginTop:"5px"}}>


        <Button
        type="primary"
        loading={loading}
        
            
            onClick={getCropData}
            disabled={!imageSelected}>Continue</Button>

</div>
{/* <div>

{
    loading ?
    ""

        // <Spinner animation="border" role="status"></Spinner>
        :
        <Button
        type="primary"
            onClick={getCropData}
            disabled={!imageSelected}>Continue</Button>
}
</div> */}


         
                      
                      
          </Card>
        </Col>
      </Row>
  </>
  )
}

export default DocPassport