import React, { useState, useEffect } from "react";


import Participant from "./Participant";
import config from "../../../Config";

function RecentParticipants({ data, loading }) {

    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if (loading === false) 
        {
            setParticipants(data);
        }
    }, [loading])

    // console.log(participants);

    const renderParticipants = () => {
        if (participants && participants.length > 0) {
            return participants.map((item, index) => {
                return (
                    
                        <Participant key={index} photo={config.img_path + item.Img_File} name={item.Full_Name} email={item.Email_Address} />
                    
                );
            });
        } else {
            return <p>No participants available</p>; // Or any other fallback message
        }
    };
    

    return (
        <>
            <h3 className="card-title">Recent Participants</h3>
            <ul className="participant-list">
                {renderParticipants()}
            </ul>
        </>
    );
}

export default RecentParticipants;