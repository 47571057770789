import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import Pagination from "../../member/General/Pagination";
import { useParams } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import TowergadRightsCheck from "../TowergadRightsCheck";

import RewardModel from "./RewardModel";
import RankModel from "./RankModel";
import { message } from "antd";

import MystryBoxModel from "./MystryBoxModel";
import AddBalanceModel from "./AddBalanceModel";

function MembersList(props) {
  const userstatus = useParams();
  const userstatusVal = userstatus.userstatus;

  // //console.log(userstatus.userstatus);

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [validatedReward, setValidatedReward] = useState(false);
  const [validatedMystryBox, setValidatedMystryBox] = useState(false);

  const [loadingUpdateBtn, setLoadingUpdateBtn] = useState(false);
  const [loadingUpdateBtnReward, setLoadingUpdateBtnReward] = useState(false);
  const [loadingUpdateBtnMystryBox, setLoadingUpdateBtnMystryBox] =
    useState(false);

  const [selectedMemberID, setSelectedMemberID] = useState(0);
  const [selectedMember, setSelectedMember] = useState({});

  const [messageApi, contextHolder] = message.useMessage();

  //image

  const [dragging, setDragging] = useState(false);
  const [imageSelected, setImageSelected] = useState(false);

  const [cropper, setCropper] = useState();
  const [image, setImage] = useState("");

  const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

  //   //console.log(status);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [show, setShow] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [showMystryBox, setShowMystryBox] = useState(false);

  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  //filters
  const [status, setStatus] = useState("Active");
  const [filteremail, setFilterEmail] = useState("");
  const [filterFullname, setFilterFullname] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterminBalance, setFilterminBalance] = useState(0);
  const [filterMemberType, setFilterMemberType] = useState("");
  const [filterSortBy, setFilterSortBy] = useState(0);
  const [filterStatus, setFilterStatus] = useState("");
  const [UserPassword, setUserPassword] = useState(null);
  const [totalBalance, settotalBalance] = useState(0);
  const [totalPackage, settotalPackage] = useState(0);

  let navigate = useNavigate();

  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => {
    document.title = "Manage Members";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      // AccessKey: AccessKey,
      // UserID: UserID,
      // pageNo: pageNo,
      // pageSize: pageSize,
      //   Status: userstatusVal ? userstatusVal : "",
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      startDate: filterStartDate,
      endDate: filterEndDate,
      minimumBalance: filterminBalance,
      Full_Name: filterFullname,
      Email_Address: filteremail,
      MemberType: filterMemberType,
      Status: userstatusVal ? userstatusVal : filterStatus,
      sortBy: filterSortBy,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMembersList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log(data);

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          settotalPackage(response.data.totalPackage);
          settotalBalance(response.data.totalBalance);

          ////console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/towergad/login");
        } else {
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, status, userstatusVal]);

  const handleClose = () => {
    setShow(false);
    setSelectedMember({});
    // setLoadingUpdateBtn(false);
  };
  const handleShow = (item) => {
    setShow(true);
    setSelectedMemberID(item.Nodes_IN_PK_Code);
    setSelectedMember(item);

    console.log(item);
    // setEmployeeName(item.Name);
  };

  const handleCloseReward = () => {
    setShowReward(false);
    setSelectedMember({});
    setSelectedMemberID("");
    setImageSelected(false);

    // setLoadingUpdateBtn(false);
  };

  const handleCloseBalanceModal = () => {
    setShowBalanceModal(false);
    setSelectedMember({});
    setSelectedMemberID("");
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
    setSelectedMember({});
    setSelectedMemberID("");
    setUserPassword(null);
  };

  const handleShowReward = (item) => {
    setShowReward(true);
    setSelectedMemberID(item.Nodes_IN_PK_Code);
    setSelectedMember(item);

    console.log(item);
    // setEmployeeName(item.Name);
  };

  const handleCloseMystryBox = () => {
    setShowMystryBox(false);
    setSelectedMember({});
    setSelectedMemberID("");
    setImageSelected(false);

    // setLoadingUpdateBtn(false);
  };
  const handleShowMystryBox = (item) => {
    setShowMystryBox(true);
    setSelectedMemberID(item.Nodes_IN_PK_Code);
    setSelectedMember(item);

    console.log(item);
    // setEmployeeName(item.Name);
  };

  const handleShowAddBalance = (item) => {
    setShowBalanceModal(true);
    setSelectedMemberID(item.Nodes_IN_PK_Code);
    setSelectedMember(item);

    //console.log(item);
    // setEmployeeName(item.Name);
  };

  const handleShowPassword = (item) => {
    setSelectedMember(item);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberID: item.Nodes_IN_PK_Code,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Users/ViewPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setUserPassword(response.data.status_message);
          setShowPasswordModal(true);
        } else {
        }

        //
      })
      .catch(function (error) {
        //setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const onChange = (e) => {
    e.preventDefault();
    setImageSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      setImageSelected(false);
      messageApi.open({
        type: "error",
        content: "Image size must be less than 2 MB",
      });

      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setImageSelected(true);
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {
      if (file.size > MAX_IMAGE_SIZE) {
        setImageSelected(false);
        messageApi.open({
          type: "error",
          content: "Image size must be less than 2 MB",
        });

        return;
      }

      // //console.log(file.size);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        if (cropper) {
          cropper.replace(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{count++ + pageSize * (pageNo - 1)}</td>
            <td>{item.Email_Address}</td>
            <td>{dayjs(item.Created_Date).format("DD/MM/YYYY")}</td>
            <td>{item.PINCode}</td>
            <td>{item.User_Name}</td>
            <td>{item.Investment_Balance}</td>
            <td>{item.Current_Balance}</td>
            <td>
              {item.Investment_Balance > 0 ? (
                <span
                  style={{ fontSize: "20px" }}
                  className="material-symbols-outlined tick yes ms-2"
                >
                  done
                </span>
              ) : (
                <span></span>
              )}
            </td>
            <td>
              {item.Investment_Balance == 0 ? (
                <span
                  style={{ fontSize: "20px" }}
                  className="material-symbols-outlined tick yes ms-2"
                >
                  done
                </span>
              ) : (
                <span></span>
              )}
            </td>
            <td>
              {item.Compounding == true ? (
                <span
                  style={{ fontSize: "20px" }}
                  className="material-symbols-outlined tick yes ms-2"
                >
                  done
                </span>
              ) : (
                <span></span>
              )}
            </td>
            <td>
              {item.Compounding == false ? (
                <span
                  style={{ fontSize: "20px" }}
                  className="material-symbols-outlined tick yes ms-2"
                >
                  done
                </span>
              ) : (
                <span></span>
              )}
            </td>
            <td>
              {/* {item.Is_Blocked ? (
                <span
                  style={{ fontSize: "20px" }}
                  className="material-symbols-outlined tick yes ms-2"
                >
                  done
                </span>
              ) : (
                <span
                  style={{ backgroundColor: "#d2d2d2", fontSize: "20px" }}
                  className="material-symbols-outlined tick no ms-2"
                >
                  close
                </span>
              )} */}
              {item.Is_Blocked == true && (
                <span
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "2px",
                    borderRadius: "50%",
                  }}
                  className="material-symbols-outlined tick no ms-2"
                >
                  close
                </span>
              )}
            </td>

            <td className="text-center">
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="button button-outline-primary"
                    align="end"
                    id={`dropdown-basic-${index}`}
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <NavLink
                      className="dropdown-item"
                      target="_blank"
                      to={`/towergad/userstatistics/update-profile/${item.Nodes_IN_PK_Code}`}
                    >
                      Update Profile
                    </NavLink>
                  <Dropdown.Item>
                      Block User
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShow(item)}>
                      Add Rank
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShowReward(item)}>
                      Add Reward
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShowMystryBox(item)}>
                      Add Mystry Box
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShowAddBalance(item)}>
                      Add Balance
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleShowPassword(item)}>
                      Show Password
                    </Dropdown.Item>

                    <NavLink
                      className="dropdown-item"
                      to={`/towergad/towergad-Referrals/${item.Nodes_IN_PK_Code}`}
                    >
                      Referral
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      to={`/towergad/member-network/${item.Nodes_IN_PK_Code}`}
                    >
                      Network
                    </NavLink>

                    <NavLink
                      target="_blank"
                      className="dropdown-item"
                      to={`/towergad/transactions/trading/${item.Nodes_IN_PK_Code}`}
                    >
                      View Transactions
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            </td>

            <td className="text-center"></td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="11">
            <h4 className="mt-3" style={{ fontSize: "15px", color: "white" }}>
              No Record Exists
            </h4>
          </td>
        </tr>
      );
    }
  };

  const handleAddRank = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingUpdateBtn(true);
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberID: selectedMemberID,
        RankName: event.target.elements.Rank_Name.value,
      };

    //  console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Users/ChangeRank",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            messageApi.open({
              type: "success",
              content: response.data.status_message,
            });
            setIsUpdated(true);
          } else {
            messageApi.open({
              type: "error",
              content: response.data.status_message,
            });
          }

          // setIsSubjectAdded(true);
          setLoadingUpdateBtn(false);
          handleClose();
        })
        .catch(function (error) {
          //setLoading(false);
          messageApi.open({
            type: "error",
            content: "Network Error",
          });
        });
    }

    setValidated(true);
  };

  const handleAddReward = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingUpdateBtnReward(true);
      if (typeof cropper !== "undefined") {
        var cropData1 = cropper.getCroppedCanvas().toDataURL();
        // setImageCropped(true);
        var cleanerBase64 = cropData1.substring(22);

        const data = {
          AccessKey: AccessKey,
          UserID: UserID,
          MemberID: selectedMemberID,
          RewardName: event.target.elements.Reward_Name.value,
          RewardAmount: event.target.elements.Reward_Amount.value,
          RewardDate: event.target.elements.Reward_Date.value,
          RewardPhoto: cleanerBase64,
        };

       // console.log(data);

        var api_config = {
          method: "post",
          url: config.base_url + "Users/AddReward",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(api_config)
          .then(function (response) {
            console.log(response.data);
            if (response.data.status_code == 1) {
              messageApi.open({
                type: "success",
                content: response.data.status_message,
              });
              setLoadingUpdateBtnReward(false);

              // setIsUpdated(true);
            } else {
              messageApi.open({
                type: "error",
                content: response.data.status_message,
              });
            }

            // setIsSubjectAdded(true);
            setLoadingUpdateBtnReward(false);
            handleCloseReward();
          })
          .catch(function (error) {
            //setLoading(false);
            messageApi.open({
              type: "error",
              content: "Network Error",
            });
          });
      }
    }

    setValidatedReward(true);
  };

  const handleAddMystryBox = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingUpdateBtnMystryBox(true);

      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        MemberID: selectedMemberID,
        Dated: event.target.elements.MystryBox_Date.value,
        DeductionAmount: event.target.elements.MystryBox_DeductionAmount.value,
        ProfitAmount: event.target.elements.ProfitAmount_Date.value,
      };

     // console.log(data);

      var api_config = {
        method: "post",
        url: config.base_url + "Users/AddMystryBox",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.status_code == 1) {
            messageApi.open({
              type: "success",
              content: response.data.status_message,
            });
            setLoadingUpdateBtnMystryBox(false);

            // setIsUpdated(true);
          } else {
            messageApi.open({
              type: "error",
              content: response.data.status_message,
            });
          }

          // setIsSubjectAdded(true);
          setLoadingUpdateBtnMystryBox(false);
          handleCloseMystryBox();
        })
        .catch(function (error) {
          //setLoading(false);
          messageApi.open({
            type: "error",
            content: "Network Error",
          });
        });
    }
    setValidatedMystryBox(true);
  };

  const handleSearch = () => {
    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      pageNo: pageNo,
      pageSize: pageSize,
      startDate: filterStartDate,
      endDate: filterEndDate,
      minimumBalance: filterminBalance,
      User_Name: filterFullname,
      Email_Address: filteremail,
      MemberType: filterMemberType,
      Status: filterStatus,
      sortBy: filterSortBy,
    };

   // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMembersList",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
      //  console.log(response.data);
        if (response.data.status_code == 1) {
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
          ////console.log(totalRecords);
        } else if (
          (response.data.status_code === 0 &&
            response.data.status_message === "Invalid Access Key.") ||
          response.data.status_code === 2
        ) {
          navigate("/towergad/login");
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <>
      {contextHolder}

      <TowergadRightsCheck />

      <AddBalanceModel
        show={showBalanceModal}
        handleClose={handleCloseBalanceModal}
        selectedMember={selectedMember}
      />

      <RankModel
        show={show}
        handleClose={handleClose}
        validated={validated}
        handleAddRank={handleAddRank}
        loadingUpdateBtn={loadingUpdateBtn}
        selectedMember={selectedMember}
      />

      {/* model for add reward  */}
      <RewardModel
        showReward={showReward}
        handleCloseReward={handleCloseReward}
        validatedReward={validatedReward}
        handleAddReward={handleAddReward}
        loadingUpdateBtnReward={loadingUpdateBtnReward}
        selectedMember={selectedMember}
        onChange={onChange}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        dragging={dragging}
        imageSelected={imageSelected}
        image={image}
        setCropper={setCropper}
      />

      <MystryBoxModel
        showMystryBox={showMystryBox}
        handleCloseMystryBox={handleCloseMystryBox}
        validatedMystryBox={validatedMystryBox}
        handleAddMystryBox={handleAddMystryBox}
        loadingUpdateBtnMystryBox={loadingUpdateBtnMystryBox}
        selectedMember={selectedMember}
      />

      <div className="admin-content">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="d-title m-0">Manage Members</div>
          </div>
          <div className="col-md-6 text-end">
            <div className="">
              <NavLink to="/towergad/userstatistics/dashboard">
                <button type="button" className="button button-primary">
                  Back
                </button>
              </NavLink>
              <button
                onClick={() => {
                  setShowFilters((prevIsActive) => !prevIsActive);
                }}
                className="button button-primary  ms-3"
                style={{
                  fontWeight: "500",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
                <i class="material-symbols-outlined">filter_list</i>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div className="card card-table mb-3 mt-4">
            <div className="card-inner">
              <div className="form_default">
                <div className="row p-3">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Start Date</label>
                      <div className="inputwrap mt-2 mb-2">
                        <input
                          className="form-control"
                          type="date"
                          value={filterStartDate}
                          onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">End Date</label>
                      <div className="inputwrap mt-2 mb-2">
                        <input
                          className="form-control"
                          type="date"
                          value={filterEndDate}
                          onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Minimum Balance</label>
                      <div className="inputwrap mt-2 mb-2">
                        <input
                          className="form-control"
                          type="number"
                          value={filterminBalance}
                          onChange={(e) => setFilterminBalance(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Username</label>
                      <div className="inputwrap mt-2 mb-2">
                        <input
                          className="form-control"
                          type="text"
                          value={filterFullname}
                          onChange={(e) => setFilterFullname(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Email Address</label>
                      <div className="inputwrap mt-2 mb-2">
                        <input
                          className="form-control"
                          type="email"
                          value={filteremail}
                          onChange={(e) => setFilterEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Member Type</label>
                      <div className="inputwrap mt-2 mb-2">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={filterMemberType}
                          onChange={(e) => setFilterMemberType(e.target.value)}
                        >
                          <option value="All" selected>
                            All
                          </option>
                          <option value="Standard"> Standard</option>
                          <option value="Trader"> Trader</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Select Status</label>
                      <div className="inputwrap mt-2 mb-2">
                        <select
                          id="dropdown"
                          className="form-select"
                          // value={userstatusVal ? userstatusVal : status}
                          // onChange={(e) => setStatus(e.target.value)}
                          value={filterStatus}
                          onChange={(e) => setFilterStatus(e.target.value)}
                        >
                          <option value="" selected>
                            All
                          </option>
                          <option value="Active"> Active</option>
                          <option value="Inactive"> Inactive</option>
                          <option value="Paid"> Paid</option>
                          <option value="Free"> Free</option>
                          <option value="Ambassador"> Ambassador</option>
                          <option value="Blocked"> Blocked</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Sort By</label>
                      <div className="inputwrap mt-2 mb-2">
                        <select
                          id="dropdown"
                          className="form-select"
                          value={filterSortBy}
                          onChange={(e) => setFilterSortBy(e.target.value)}
                        >
                          <option value="Oldest On Top">
                            Oldest On Top
                          </option>
                          <option value="Latest On Top">Latest On Top</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 offset-11 mb-2">
                  <button
                    onClick={handleSearch}
                    className="button button-primary"
                    style={{
                      fontWeight: "500",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Pincode</th>
                  <th>Usermame</th>
                  <th>Package</th>
                  <th>Balance</th>
                  <th>Paid</th>
                  <th>Free</th>
                  <th>Active</th>
                  <th>Inactive</th>
                  <th>Blocked</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colspan="11">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>
                  {renderLedger()}
                  <tr>
                  <td className="text-center" colspan="5"> Grand Total </td>
                    <td>${totalPackage}</td>
                    <td>${totalBalance}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        className="theme-modal"
        show={showPasswordModal}
        onHide={handleClosePasswordModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-5">
            <h4 style={{ fontSize: "18px" }}>
              {selectedMember.User_Name} : {UserPassword}
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MembersList;
