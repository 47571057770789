import React, { useState, useEffect } from "react";

import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Row, Col, Button, Form, Input, message } from "antd";

import Logo from "../../assets/images/logo/towergear_black.png";
import ImgEmailVerified from "../../assets/images/email-verified.svg";
import ImgEmailFailed from "../../assets/images/emailfailed.svg";
import config from "../../Config";
import axios from "axios";
import { NavLink,Link, useLocation, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [UserName1, setUserName1] = useState(localStorage.getItem("User_Name"));
  const [IsVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const SecurityCode = searchParams.get("SecurityCode");
  const UserName = searchParams.get("UserName");
  const ExpiryDate = searchParams.get("ExpiryDate");
  const email = searchParams.get(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (email) {
      setIsVerified(false);
    } else {

      const data = {
        SecurityCode: SecurityCode,
        UserName: UserName,
        ExpiryDate: ExpiryDate,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/VerifyEmailAddress",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {

          if (response.data.status_code == 1) {
            setIsVerified(true);
            setLoading(false);
            //navigate('/login');
          } else {
            messageApi.open({
              type: "error",
              content: response.data.status_message,
            });

            setIsVerified(false);
          }

        })
        .catch(function (error) {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: "Network Error..",
          });
        });
    }
  }, []);

  const ResendEmail = () => {

    setLoading(true);
    const data = {
      UserName: UserName1,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/SendSignUpEmail",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
          setLoading(false);
        }

        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Network Error..",
        });
      });
  };

  return (
    <>
      {contextHolder}
      <div className="auth-wrap">
        <div className="auth-container">
          <div className="auth-head" style={{ justifyContent: "center" }}>
            <div className="logo">
              <img src={Logo} />
            </div>
          </div>
          <Row style={{ alignItems: "center" }}>
            <Col md={{ span: 8, offset: 8 }} xs={24}>
              <div className="card-black card-auth-form">
                {IsVerified ? (
                  <>
                    <h2>Email Verified!</h2>
                    <div className="auth_body">
                      <p>
                        Congratulations!
                        <br />
                        Your email verification is complete! Welcome to the Tower Gear Community.
                      </p>
                      <div className="text-center my-5">
                        <img src={ImgEmailVerified} style={{ maxWidth: "100px" }} />
                      </div>
                      <Link to="/login">
                        <Button type="primary" shape="round" block loading={loading}>
                          Login Now
                        </Button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <h2>Email Verification Failed!</h2>
                    <div className="auth_body">
                      <p>
                        Unfortunately, your email verification is not complete.
                        Please try again now.
                      </p>
                      <div className="text-center my-5">
                        <img src={ImgEmailFailed} style={{ maxWidth: "110px" }} />
                      </div>
                      <Button type="primary" onClick={ResendEmail} shape="round" block loading={loading}>
                        Resend Verify Link
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
