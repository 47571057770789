import React, { useEffect } from 'react'
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";
import { Row, Col } from 'antd';
import money from '../../../../assets/images/landing/showing-money.jpg';

const Commodites = () => {
    useEffect(() => {
        document.title = "Commodites";
    }, []);
    return (
        <>
            <div className="">
                <Header />
                <Page_head page_title='Commodites' />
                <div className="simple-section commodites-section">
                    <div className="tg-container">
                        <Row
                            gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                            align={'middle'} row-rewa>
                            <Col md={{ span: "12" }} xs={24}>
                                <div className="section-title">
                                    <h3>
                                        <span> UNLOCKING COMMODITY EARNINGS:</span> <br />
                                        A CLOSER LOOK
                                    </h3>
                                </div>
                                <div className="section-detail">
                                    Commodities are a cornerstone of our investment strategy. <br /><br />
                                    <span>HOW COMMODITY EARNINGS WORK
                                    </span>
                                </div>
                                <ul className='count-list'>
                                    <li>
                                        <span>1</span>
                                        <div className="content">
                                            <h5>MARKET DEMAND</h5>
                                            <div className="section-detail">
                                                Commodities are driven by market demand As demand for products, like Coca-Cola remains steady, so does your potential for earnings.
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <span>2</span>
                                        <div className="content">
                                            <h5>HEDGING AGAINST INFLATION</h5>
                                            <div className="section-detail">
                                                Commodities Like gold act as a hedge again. inflation, preserving your wealth in time  economic uncertainty.
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <span>3</span>
                                        <div className="content">
                                            <h5>DIVERSIFICATION</h5>
                                            <div className="section-detail">
                                                DiversifyIng your portfolio with commodities helps spread risk and enhances your chances of overall profitability.
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={{ span: "12" }} xs={24}>
                                <img src={money} alt="" style={{ borderRadius: "10px" }} />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="simple-section bg-black">
                    <div className="tg-container">
                        <div className="experience">
                            <div className="section-title text-center">
                                <h3>
                                    Commodity Investments
                                </h3>
                            </div>
                            <div className="section-detail text-center">
                                Unlocking Profit Potential
                            </div>
                            <Row
                                className='mt-5'
                                gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                            >
                                <Col md={6} xs={24}>
                                    <div className="icon-card">
                                        <span>
                                            <i class="material-symbols-outlined">
                                                moving
                                            </i>
                                        </span>
                                        <h3>
                                            Diversify with Commodity Investments
                                        </h3>
                                        <p>
                                            Explore a world of commodities. From precious metals to energy sources, diversify your portfolio strategically.
                                        </p>

                                    </div>
                                </Col>
                                <Col md={6} xs={24}>
                                    <div className="icon-card">
                                        <span>
                                            <i class="material-symbols-outlined">
                                                crowdsource
                                            </i>
                                        </span>
                                        <h3>
                                            Smart Investing in Commodities
                                        </h3>
                                        <p>
                                            Navigate volatile markets with confidence. Discover our insights for informed, profitable commodity investments.
                                        </p>

                                    </div>
                                </Col>
                                <Col md={6} xs={24}>
                                    <div className="icon-card">
                                        <span>
                                            <i class="material-symbols-outlined">
                                                currency_bitcoin
                                            </i>
                                        </span>
                                        <h3>
                                            Commodities: Your Path to Wealth
                                        </h3>
                                        <p>
                                            Uncover the power of commodities. Invest wisely and unlock lucrative opportunities for long-term wealth accumulation.
                                        </p>
                                        {/* <Link>
                                        Get Started
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </Link> */}
                                    </div>
                                </Col>
                                <Col md={6} xs={24}>
                                    <div className="icon-card">
                                        <span>
                                            <i class="material-symbols-outlined">
                                                sim_card
                                            </i>
                                        </span>
                                        <h3>
                                            Strategic Commodity Ventures
                                        </h3>
                                        <p>
                                            Partner with Tower Gear for strategic commodity ventures. Tap into our expertise for sustainable profit growth.
                                        </p>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Commodites