import React, { useEffect } from 'react'
import Website_Header from './include/Website_Header'
import image1 from '../../assets/images/landing/image1.jpg'
import icon_1 from '../../assets/images/landing/icon-1.png'
import icon_4 from '../../assets/images/landing/icon-4.png'
import icon_3 from '../../assets/images/landing/icon-3.png'
import icon_6 from '../../assets/images/landing/icon-6.png'
import Company_2 from '../../assets/images/landing/Trading-Company-2.png'
import Company_3 from '../../assets/images/landing/Trading-Company-3.png'
import Candlesticks from '../../assets/images/landing/Candlesticks.png'
import line from '../../assets/images/landing/line.png';
import analysis from '../../assets/images/landing/analysis.png';
import user from '../../assets/images/landing/user.jpeg';
import Instruments from '../../assets/images/landing/UI-Element.-04.png'
import Cards from '../../assets/images/landing/Cards.png';
import testimonial from '../../assets/images/landing/testimonial-side.png';
import girl from '../../assets/images/girl.png';
import boy from '../../assets/images/boy.png';
import bitcoin from '../../assets/images/landing/bitcoin.svg';
import exchange from '../../assets/images/landing/exchange.svg';
import Ownership from '../../assets/images/landing/ownership.svg';
import house from '../../assets/images/landing/house.jpg';
import earth from '../../assets/images/landing/earth.jpg';
import { Row, Col, Space, Carousel, Button } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import Footer from './include/Footer';
import { StarFilled } from '@ant-design/icons';
import Partners from './sections/Partners';
import logo from '../../assets/images/logo/logo.svg';
import Header from './include/Website_Header';
import { RightOutlined } from '@ant-design/icons';
import PartnerSlider from './sections/PartnersSlider'

const Home = () => {
    useEffect(() => {
        document.title = "Home"

    }, [])

    return (
        <>
            <Header addclass="bg-transparent" />
            <div className="hero-section-wrap">
                <img src={earth} style={{ width: "100%" }} alt="" />
                <div className='hero-section'>
                    <div className="tg-container">
                        <Row
                            gutter={30}
                        >
                            <Col md={15} xs={24}>
                                <div className="hero-title">
                                    <span>Real Estate, Exchange,</span>
                                    <br />
                                    <span>and Commodity Trading</span>
                                </div>
                                <div className="hero-description">
                                    Dive into a world of endless opportunities! Experience the future of  <br /> trading with our cutting-edge platform, <br />  where real estate, exchange, and commodity markets converge.
                                </div>
                                <Space size={20}>
                                    <NavLink to="/register">
                                        <Button type="primary">
                                            Register Now
                                        </Button>
                                    </NavLink>
                                    <NavLink to="/about">
                                        <button className="button button-secondary button-icon">
                                            About Us <span className="material-symbols-outlined">
                                                arrow_forward_ios
                                            </span>
                                        </button>
                                    </NavLink>
                                </Space>
                            </Col>
                        </Row >
                    </div>
                </div>
            </div >
            <div className="simple-section d-none bg-black status-section">
                <div className="tg-container">
                    <img src={line} style={{ width: "100%" }} alt="" />
                    <div className="tg-container">
                        <Row>
                            <Col md={4} xs={24}>
                                <h2 className='text-white'>Stats that matter</h2>
                                <div className="section-detail">Sed ut perspiciatis unde omnis iste natus err</div>
                            </Col>
                            <Col md={18} offset={2} xs={24}>
                                <Row>
                                    <Col md={6} xs={24}>
                                        <h2 className='text-white'>#1IT Broker</h2>
                                        <div className="section-detail">Company In UK</div>
                                    </Col>
                                    <Col md={6} xs={24}>
                                        <h2 className='text-white'>250+</h2>
                                        <div className="section-detail">Trading Platforms</div>
                                    </Col>
                                    <Col md={6} xs={24}>
                                        <h2 className='text-white'>30k Users</h2>
                                        <div className="section-detail">Trading</div>
                                    </Col>
                                    <Col md={6} xs={24}>
                                        <h2 className='text-white'>$45Million</h2>
                                        <div className="section-detail">Traded Volumn</div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="simple-section">
                <div className="tg-container">
                    <Row
                        gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                    >
                        <Col md={15} xs={24}>
                            <div className="section-title">
                                <h4>
                                    Explore Our Comprehensive Trading Solutions
                                </h4>
                            </div>
                        </Col>
                        <Col md={9} xs={24}>
                            <div className="section-detail">
                                Discover a range of tailored solutions designed to cater to every investor's needs. From real estate ventures to commodity trading, we provide a seamless platform for secure, profitable investments.
                            </div>
                        </Col>
                    </Row>
                    <div className="services mt-5">
                        <Row
                            gutter={[50, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                        >
                            <Col md={9} xs={24}>
                                <div className="glass-card">
                                    <div className="heading">
                                        <div className="content">
                                            <h1>99%</h1>
                                            <div className="section-detai">
                                                Accuracy In Analytics
                                            </div>
                                        </div>
                                        <img src={analysis} alt="" className='image-fluid' />
                                    </div>
                                    <div className="icon-box-title">
                                        <span class="material-symbols-outlined">
                                            monitoring
                                        </span>
                                        <div className="content">
                                            <h1>
                                                Data-Driven Investment Strategies
                                            </h1>
                                            <h3>
                                                Indice Training guide
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="section-detail">
                                        Rely on our state-of-the-art analytics with an impressive 99% accuracy rate. Our advanced algorithms and expert analysis provide unparalleled insights, guiding your investment decisions toward maximum profitability.
                                    </div>
                                </div>
                            </Col>
                            <Col md={15} xs={24}>
                                <Row gutter={[24, 25]}>
                                    <Col md={12} xs={24}>
                                        <div className="solution-icon-box">
                                            <div className="icon-box-title">
                                                <span class="material-symbols-outlined">
                                                    home_work
                                                </span>
                                                <div className="content">
                                                    <h1>
                                                        Real Estate Expertise
                                                    </h1>
                                                    <h3>
                                                        Prime Properties
                                                    </h3>
                                                </div>
                                            </div>
                                            <p>
                                                Invest in premium real estate projects, ensuring long-term value and high returns. Your gateway to lucrative property investments.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <div className="solution-icon-box">
                                            <div className="icon-box-title">
                                                <span class="material-symbols-outlined">
                                                    partner_exchange
                                                </span>
                                                <div className="content">
                                                    <h1>
                                                        Exchange Excellence
                                                    </h1>
                                                    <h3>
                                                        Global Trading Hub
                                                    </h3>
                                                </div>
                                            </div>
                                            <p>
                                                Access international markets effortlessly. Our platform offers real-time data and efficient tools for trading stocks, currencies, and more.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <div className="solution-icon-box">
                                            <div className="icon-box-title">
                                                <span class="material-symbols-outlined">
                                                    oil_barrel
                                                </span>
                                                <div className="content">
                                                    <h1>
                                                        Commodity Confidence
                                                    </h1>
                                                    <h3>
                                                        Commodity Futures
                                                    </h3>
                                                </div>
                                            </div>
                                            <p>
                                                Dive into the world of commodities with confidence. Trade in gold, oil, and other commodities with expert insights and robust analytics.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <div className="solution-icon-box">
                                            <div className="icon-box-title">
                                                <span class="material-symbols-outlined">
                                                    encrypted
                                                </span>
                                                <div className="content">
                                                    <h1>
                                                        Secure Investments
                                                    </h1>
                                                    <h3>
                                                        Safe & Reliable
                                                    </h3>
                                                </div>
                                            </div>
                                            <p>
                                                Rest easy knowing your investments are protected. We prioritize security, ensuring your transactions and data are always safeguarded.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="simple-sectione essential">
                <div className="tg-container">
                    <Row
                        gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}

                        align={'middle'}>
                        <Col md={{ span: "12" }}>

                            <div className="section-title">
                                <h3 className=''>
                                    Why Is a Home Essential for Us
                                </h3>
                            </div>
                            <div className="section-detail">
                                Home is where memories are made, dreams take shape, and your journey unfolds. It's your sanctuary, your canvas your legacy.
                            </div>

                            <Button type='primary' className="mt-3">
                                About Us
                            </Button>
                        </Col>
                        <Col md={{ span: "6" }}>
                            <div className="subtitle">
                                WHY CHOOSE US?
                            </div>
                            <ul className='check_list mt-4'>
                                <li>
                                    <span class="material-symbols-outlined">
                                        check
                                    </span>
                                    Stability and Security
                                </li>
                                <li>
                                    <span class="material-symbols-outlined">
                                        check
                                    </span>
                                    Investment and Financial Security
                                </li>
                                <li>
                                    <span class="material-symbols-outlined">
                                        check
                                    </span>
                                    Personal Space and Privacy
                                </li>
                                <li>
                                    <span class="material-symbols-outlined">
                                        check
                                    </span>
                                    Community and Social Connections
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="simple-section">
                <div className="tg-container">
                    <div className="experience">
                        <Row
                            gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                            className='title'>
                            <Col md={15} xs={24}>
                                <div className="section-title">
                                    <h3>
                                        Unlock Your Potential with Tower Gear
                                    </h3>
                                </div>
                                <div className="section-detail">
                                    Welcome to Tower Gear, where your financial aspirations meet innovative solutions. Explore our comprehensive range of services designed to elevate your trading experience and enhance your lifestyle.
                                </div>
                            </Col>
                            <Col md={9} xs={24} className="text-center text-md-end">
                                <NavLink to="/login">
                                    <button className="button button-primary button-icon">
                                        Get Started
                                        <span className="material-symbols-outlined">
                                            north_east
                                        </span>
                                    </button>
                                </NavLink>
                            </Col>
                        </Row>
                        <Row
                            className='mt-5'
                            gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                        >
                            <Col md={9} xs={24} className="">
                                <div className="Training-card">
                                    <div className="heading">
                                        UNDERSTANDING EXCHANGES
                                    </div>
                                    <h5>Your Gateway to Profitable Investments</h5>
                                    <img src={Instruments} alt="" />
                                </div>
                            </Col>
                            <Col md={15} xs={24} className="">
                                <div className="currency-card">
                                    <div className="content">
                                        <div className="heading">
                                            Maximize your wealth through strategic investments in exchanges
                                        </div>
                                        <div className='section-detail'>Diversify portfolios, seize market opportunities, and achieve financial goals with Tower Gear's expert guidance.</div>
                                        <div className="section-title my-3">
                                            <h3 className='white'>$199M</h3>
                                        </div>
                                        <div className="subtitle">
                                            <span>
                                                Traded Volume
                                            </span>
                                        </div>
                                    </div>
                                    <img src={Cards} alt="" className='cardit-card' />
                                </div>
                                <img src={image1} />
                                {/* <div className="offer-card mt-3">
                                    
                                    <div className="content" style={{display: "none"}}>
                                        <div className="heading">
                                            We strive to offer an unparalleled experience
                                        </div>
                                        <div className='section-detail'>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                        </div>
                                        <img src={Cards_3} alt="" />
                                    </div>
                                    
                                </div> */}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="simple-section bg-gray">
                <div className="tg-container">
                    <div className="experience">
                        <div className="section-title text-center">
                            <h3 className=''>
                                Commodity <span>Investments</span>
                            </h3>
                        </div>
                        <div className="section-detail text-center">
                            Unlocking Profit Potential
                        </div>
                        <Row
                            className='mt-5'
                            gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                        >
                            <Col md={6} xs={24}>
                                <div className="icon-card">
                                    <span>
                                        <img src={icon_1} />
                                    </span>
                                    <h3>
                                        Diversify with Commodity Investments
                                    </h3>
                                    <p>
                                        Explore a world of commodities. From precious <br /> metals to energy sources, diversify your portfolio strategically.
                                    </p>

                                </div>
                            </Col>
                            <Col md={6} xs={24}>
                                <div className="icon-card">
                                    <span>
                                        <img src={icon_4} />
                                    </span>
                                    <h3>
                                        Smart Investing in Commodities
                                    </h3>
                                    <p>
                                        Navigate volatile markets with confidence. Discover our insights for informed, profitable commodity investments.
                                    </p>

                                </div>
                            </Col>
                            <Col md={6} xs={24}>
                                <div className="icon-card">
                                    <span>
                                        <img src={icon_3} />
                                    </span>
                                    <h3>
                                        Commodities: Your Path to Wealth
                                    </h3>
                                    <p>
                                        Uncover the power of commodities. Invest wisely and unlock lucrative opportunities for long-term wealth accumulation.
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} xs={24}>
                                <div className="icon-card">
                                    <span>
                                        <img src={icon_6} />
                                    </span>
                                    <h3>
                                        Strategic Commodity Ventures
                                    </h3>
                                    <p>
                                        Partner with Tower Gear for strategic commodity ventures. Tap into our expertise for sustainable profit growth.
                                    </p>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="simple-section">
                <div className="tg-container">
                    <Row align={'middle'} className='mb-5'>
                        <Col md={24} align={'middle'}>
                            <div className="section-title">
                                <h3>
                                    <span>UNLOCK</span> YOUR WEALTH
                                </h3>
                            </div>
                            <div className="section-detail">
                                Our Triple-Threat Investment Approach
                            </div>
                        </Col>
                    </Row>
                    <Row
                        gutter={[25, { xs: 30, sm: 30, md: 24, lg: 32 }]}
                        align="center"
                        className="mt-5" style={{ alignItems: "flex-start" }}
                    >
                        <Col md={6} xs={24}>
                            <div className="single-feature">
                                <div className="icon-box">
                                    <img src={Ownership} alt="" />
                                </div>

                                <h3>Property</h3>

                                <p>Discover real estate opportunities that resonate with your aspirations. Secure your future with wise property investments.</p>
                            </div>
                        </Col>
                        <Col md={6} xs={24}>
                            <div className="single-feature">
                                <div className="icon-box">
                                    <img src={exchange} alt="" />
                                </div>

                                <h3>Exchange</h3>

                                <p>Empower your financial journey with our global exchange solutions. Seamlessly trade stocks, currencies, and assets, maximizing your wealth potential.</p>
                            </div>
                        </Col>
                        <Col md={6} xs={24}>
                            <div className="single-feature">
                                <div className="icon-box">
                                    <img src={bitcoin} alt="" />
                                </div>

                                <h3>Commodity</h3>

                                <p>Explore the world of commodities with confidence. Invest in gold, oil, and more, leveraging our expert insights for profitable commodity ventures.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="simple-section people-say">
                <div className="tg-container">

                    <div className="subtitle text-center">
                        <span>

                        </span>
                    </div>
                    <div className="section-title text-center mb-5">
                        <h3 className='white'>
                            WHAT OUR CLIENTS SAY <span>Tower Gear</span>
                        </h3>
                    </div>


                    <Row gutter={50} align="top">
                        <Col md={{ span: 20, offset: 2 }} xs={24}>
                            <Carousel
                                autoplay
                                slidesToShow={1}
                                slidesToScroll={1}
                                dots={true}
                                dotPosition="bottom"
                                className="centered-carousel"
                                responsive={[
                                    {
                                        breakpoint: 768, // Adjust this breakpoint as needed
                                        settings: {
                                            slidesToShow: 1, // Show 1 slide on mobile view
                                            slidesToScroll: 1,
                                        },
                                    },
                                    // Add more breakpoints and settings as needed
                                ]}
                            >
                                <div>
                                    <div className="rating-card">
                                        <div className="starts">
                                            <StarFilled />
                                            <StarFilled />
                                            <StarFilled />

                                        </div>
                                        <p className="section-detail">"TowerGear has been instrumental in helping me navigate the complex world of investments. Their personalized approach and transparent communication make them stand out. I've witnessed steady growth in my portfolio, all thanks to TowerGear's guidance and expertise."
                                        </p>
                                        <div className="profile">
                                            <div className="user-detail">
                                                <div className="user_name">Jack</div>
                                                <div className="job">Trader</div>
                                            </div>
                                            <div className="user">
                                                <img src={boy} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="rating-card">
                                        <div className="starts">
                                            <StarFilled />
                                            <StarFilled />
                                            <StarFilled />

                                        </div>
                                        <p className="section-detail">"TowerGear has been instrumental in helping me navigate the complex world of investments. Their personalized approach and transparent communication make them stand out. I've witnessed steady growth in my portfolio, all thanks to TowerGear's guidance and expertise."
                                        </p>
                                        <div className="profile">
                                            <div className="user-detail">
                                                <div className="user_name">Jerrell Weatherby</div>
                                                <div className="job">Trader</div>
                                            </div>
                                            <div className="user">
                                                <img src={user} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="rating-card">
                                        <div className="starts">
                                            <StarFilled />
                                            <StarFilled />
                                            <StarFilled />

                                        </div>
                                        <p className="section-detail">"I'm truly impressed with TowerGear's commitment to their clients. Their tailored investment strategies have allowed me to achieve my financial goals faster than I ever thought possible. If you're serious about securing your financial future, I wholeheartedly recommend TowerGear."
                                        </p>
                                        <div className="profile">
                                            <div className="user-detail">
                                                <div className="user_name">Jani</div>
                                                <div className="job">Trader</div>
                                            </div>
                                            <div className="user">
                                                <img src={girl} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                </div>
            </div >

            <div className="simple-section">
                <div className="tg-container">
                    <PartnerSlider />
                </div>
            </div >
            <Footer />
        </>
    )
}

export default Home