import React, { useEffect } from "react";
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";
import { Collapse } from "antd";
const Faq = () => {
  useEffect(() => {
    document.title = "FAQ";
  }, []);

  const q1 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Towergear is an online trading platform that facilitates seamless trading
      of properties, exchange items, and commodities. Users can browse listings
      and connect with potential traders but cannot directly list their items on
      the website.
    </p>
  );
  const q2 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      You can easily find available properties, exchange items, and commodities
      by browsing our curated listings. Use the search filters to specify your
      preferences and view the options available for trade.
    </p>
  );
  const q3 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Towergear acts as a platform to connect buyers and sellers. To initiate a
      trade, you need to contact the sellers or buyers directly through the
      provided contact information on the listings. Towergear does not
      facilitate direct transactions on the platform.
    </p>
  );
  const q4 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Yes, Towergear prioritizes the safety and security of its users. While we
      do not handle direct transactions, we provide a secure messaging system
      for communication between buyers and sellers. We recommend using safe
      payment methods and meeting in secure locations for in-person exchanges.
    </p>
  );
  const q5 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Basic usage of Towergear, including browsing listings and contacting
      sellers/buyers, is completely free. We do not charge any fees for using
      our platform. However, please be cautious of any transaction fees that
      might apply when using external payment methods.
    </p>
  );
  const q6 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      If you come across any suspicious or fraudulent listings, please report
      them immediately to our support team. We take such reports seriously and
      will investigate the matter promptly to maintain the integrity
      of our platform.
    </p>
  );
  const items = [
    {
      key: "1",
      label: "1. What is Towergear?",
      children: q1,
    },
    {
      key: "2",
      label:
        "2. How can I find properties, exchange items, or commodities on Towergear? ",
      children: q2,
    },
    {
      key: "3",
      label:
        "3. Can I directly trade on Towergear, or do I need to contact the sellers/buyers separately?",
      children: q3,
    },
    {
      key: "4",
      label: "4. Is Towergear a secure platform for trading?",
      children: q4,
    },
    {
      key: "5",
      label: "5. Are there any fees for using Towergear?",
      children: q5,
    },
    {
      key: "6",
      label:
        "6. What should I do if I encounter a suspicious or fraudulent listing on Towergear?",
      children: q6,
    },
  ];

  return (
    <>
      <div className="">
        <Header />
        <Page_head page_title="FAQ" />

        <div className="website-section">
          <div className="auth-container terms-wrap">
            <div style={{marginTop:"20px",marginBottom:"20px"}}>

            <Collapse  defaultActiveKey={["1"]} ghost items={items} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Faq;
