import React from 'react';

import iconNetwork from "../../../assets/images/dashboard/icons/network.svg";
import imgellipseRed from "../../../assets/images/dashboard/ellipse-red.svg";
import { NavLink } from 'react-router-dom';


function NetworkCard(props) {
    return (
        <>
            <h3 className="card-title" style={{ visibility: "hidden" }}>Network</h3>
            <div className="card-network">

                <div className="card-body card-backdrop">
                  
                    <div className="position-relative">


                        <div className="network-card-head">
                            <img src={iconNetwork} />
                            <h4>My Network</h4>
                        </div>
                        <div className="network-card-body">
                            <div className="count">
                                <p className="text-dim-6">Total Members</p>
                                <h2>{props.count}</h2>
                            </div>
                            <NavLink to="/member/network">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.375 1.25V6.875C19.375 7.22047 19.0955 7.5 18.75 7.5C18.4045 7.5 18.125 7.22047 18.125 6.875V2.75875L9.19187 11.6919C9.06984 11.8139 8.90984 11.875 8.75 11.875C8.59016 11.875 8.43016 11.8139 8.30813 11.6919C8.25005 11.6339 8.20398 11.565 8.17255 11.4892C8.14112 11.4134 8.12494 11.3321 8.12494 11.25C8.12494 11.1679 8.14112 11.0866 8.17255 11.0108C8.20398 10.935 8.25005 10.8661 8.30813 10.8081L17.2413 1.875H13.125C12.7795 1.875 12.5 1.59547 12.5 1.25C12.5 0.904531 12.7795 0.625 13.125 0.625H18.75C19.0955 0.625 19.375 0.904531 19.375 1.25ZM16.875 17.5V10C16.875 9.65453 16.5955 9.375 16.25 9.375C15.9045 9.375 15.625 9.65453 15.625 10V17.5C15.625 17.8448 15.3448 18.125 15 18.125H2.5C2.15516 18.125 1.875 17.8448 1.875 17.5V5C1.875 4.65516 2.15516 4.375 2.5 4.375H10C10.3455 4.375 10.625 4.09547 10.625 3.75C10.625 3.40453 10.3455 3.125 10 3.125H2.5C1.46609 3.125 0.625 3.96609 0.625 5V17.5C0.625 18.5339 1.46609 19.375 2.5 19.375H15C16.0339 19.375 16.875 18.5339 16.875 17.5Z" fill="#f9b707" />
                                </svg>

                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NetworkCard;