import React, { useState, useEffect } from "react";

import { LockOutlined, UserOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { Row, Col, Button, Form, Input, Checkbox, message } from 'antd';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/images/logo/towergear_black.png";

import config from "../../Config";
import axios from "axios";

import { generateRandomString } from "../member/General/Functions";

function Register(props) {


    // referral
    const [RefEmail, setRefEmail] = useState(null);
    const [ParentID, setParentID] = useState(0);
    const [ReferrralExist, setReferrralExist] = useState(null);
    const [Referrral, setReferrral] = useState("");


    // loadings
    const [loading, setLoading] = useState(false);
    const [loadingReferral, setLoadingReferral] = useState(false);

    // messages
    const [messageApi, contextHolder] = message.useMessage();
    const [captcha, setCaptcha] = useState("");
    // form
    const [form] = Form.useForm();

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        setCaptcha(generateRandomString(6));
    }, []);


    useEffect(() => {
        document.title = "Register with Towergear";

        if (params.id) {

            setLoadingReferral(true);
            setParentID(params.id);

            const data = {
                AccountKey: params.id,
            };

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/GetUserByAccountKey',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(api_config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.status_code == 1) {
                        setRefEmail(response.data.Email_Address);
                        setReferrralExist(true);
                        setLoadingReferral(false);

                        form.setFieldsValue({
                            Referrer_Email: response.data.Email_Address
                        });


                    }
                    else if (response.data.status_code == 0) {
                        setReferrralExist(false);
                        setLoadingReferral(false);
                    }

                })
                .catch(function (error) {


                });


        }
    }, []);


    const onFinish = (formData) => {


        if (formData.Captcha != captcha) {
            messageApi.open({
                type: "error",
                content: "Incorrect Captcha",
            });
            return;
        }


        setLoading(true);
        formData['Referrer_Email'] = formData['Referrer_Email'] === undefined ? "" : formData['Referrer_Email'];
        const data = {
            ...formData
        };

        ////console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/SignUp',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {


                ////console.log(response.data);
                if (response.data.status_code == 1) {
                    messageApi.open({
                        type: 'success',
                        content: response.data.status_message,
                    });

                    setTimeout(() => {
                        navigate('/login');
                    }, 4000);

                    setLoading(false);

                }
                else {
                    messageApi.open({
                        type: 'error',
                        content: response.data.status_message,
                    });
                    setLoading(false);
                }




            })
            .catch(function (error) {


            });

    };


    const UpdateCaptcha = () => {
        setCaptcha(generateRandomString(6));
    };


    return (
        <>
            {contextHolder}

            <div className="auth-wrap-new swipe">
                <div className="col-left">
                    <div className="auth-form form-registration">
                        <h2>Register with Towergear</h2>

                        <Form
                            name="normal_login"
                            className="login-form"
                            layout="vertical"
                            size="large"
                            form={form}
                            onFinish={onFinish}
                        //Referrer_Email
                        // fields={!loadingReferral ? [{name: 'Referrer_Email', value: RefEmail}] : ''}
                        >

                            <Row gutter={[24, 0]}>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="Full_Name"
                                        label="Full Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your name!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            maxLength={25}
                                            placeholder="Ex. John Doe" />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="Email_Address"
                                        label="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email!',
                                            },
                                        ]}
                                    >
                                        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Ex. john@gmail.com" />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="User_Name"
                                        label="User Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a username',
                                            },
                                            {
                                                pattern: /^[^\s]+$/, // Regular expression to disallow spaces
                                                message: 'Username cannot contain spaces',
                                            },
                                        ]}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="Password"
                                        label="Password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col md={12} xs={24}>

            <Form.Item
                name="Referrer_Email"
                label="Referral Email"
                
                hasFeedback={ReferrralExist}
                validateStatus={ReferrralExist ? "success" : "error"}
                help={ReferrralExist ? "" : "Invalid Referral Account"}
            >
                <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Referral Email"
                    id="validating"
                    disabled={ReferrralExist}
                     />
            </Form.Item>
        </Col> */}
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="Referrer_Email"
                                        label="Referral Email"
                                        hasFeedback={ReferrralExist}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Invalid Referral Account',
                                            },
                                        ]}
                                    >
                                        <Input prefix={<MailOutlined className="site-form-item-icon" />}
                                            placeholder="Referral Email"
                                            id="validating"
                                            disabled={ReferrralExist}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        style={{ marginTop: "40px" }}
                                        name="remember"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please accept the terms and conditions to proceed.',
                                            },
                                        ]}

                                    >
                                        <Checkbox>I agree with Terms & Conditions</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col md={8} xs={24}>
                                    <Form.Item>
                                        <div className="Character-wrap" style={{ backgroundColor: "transparent" }}>
                                            <Input className="Character" value={captcha} readOnly />
                                            <NavLink style={{ marginTop: "10px", display: "inline-block" }} onClick={UpdateCaptcha}>
                                                Refresh The Captcha
                                            </NavLink>
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col md={10} xs={24}>
                                    <Form.Item
                                        // label="Captcha"
                                        name="Captcha"
                                        style={{ width: "100%" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input Captcha",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Captcha" />

                                    </Form.Item>
                                </Col>
                            </Row>



                            <br />
                            <Form.Item>
                                <Button type="primary" htmlType="submit" shape="round" block loading={loading}>
                                    Create my account
                                </Button>
                            </Form.Item>

                        </Form>

                    </div>
                </div>
                <div className="col-right">
                    <div className="auth-right-content">
                        <div className="auth-head">
                            <div className="logo">
                                <img src={Logo} />
                            </div>
                            <div className="auth-actions">
                                <p>Already have an account?</p>

                                <NavLink to="/login" className="button button-secondary button-round button-long">Sign In</NavLink>
                            </div>

                        </div>
                        <h3>Why Join Us?</h3>
                        <br />
                        <ul className="list-reasons">
                            <li>
                                <span>
                                    01
                                </span>
                                <div>
                                    <h4>Expertise</h4>
                                    <p>Benefit from our seasoned professionals' market knowledge for better trading decisions.</p>
                                </div>
                            </li>
                            <li>
                                <span>
                                    02
                                </span>
                                <div>
                                    <h4>Cutting-Edge Tech</h4>
                                    <p>Access advanced tools and resources to boost your trading performance.</p>
                                </div>
                            </li>
                            <li>
                                <span>
                                    03
                                </span>
                                <div>
                                    <h4>Global Network</h4>
                                    <p>Expand your reach and explore international trading opportunities.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>




        </>
    );
}

export default Register;