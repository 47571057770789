import React, { useEffect } from 'react'
import Header from "../../include/Website_Header";
import Footer from "../../include/Footer";
import Page_head from "../../../member/layout/Page_head";
import mobile from "../../../../assets/images/landing/mobile.png";
import { Row, Col, Space, Carousel, Button } from 'antd';

const MobileApp = () => {
  useEffect(() => {
    document.title = "Mobile App";
  }, []);
  return (
    <>
      <div className="">
        <Header />
        <Page_head page_title='Mobile App' />
        <div className="simple-section bg-black">
          <div className="tg-container">
            <Row justify="center" align="middle" className='mt-5'>
              <Col md={14} xs={24} align="middle">
                <div className="section-title">
                  <h3>Experience Seamless Trading with <span>Towergear's</span> Mobile App</h3>

                </div>
                <div className="section-detail">
                  Empowering Your Investments Anytime, Anywhere
                </div>
              </Col>
            </Row>
            <Row justify="center" align="middle" className='mt-5'>
              <Col md={9} xs={24}>
                <ul className='list-right'>
                  <li>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="40" height="40" rx="20" fill="#242424" />
                      <rect x="15" y="15" width="10" height="10" rx="5" fill="#AFF704" />
                    </svg>
                    <div className="content">
                      <h3>Instant Trading</h3>
                      <div className="section-detail"> Trade at the speed of thought with Towergear's mobile app. Execute buy and sell orders instantly, ensuring you never miss a lucrative opportunity in the market.
                      </div>
                    </div>
                  </li>
                  <li>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="40" height="40" rx="20" fill="#242424" />
                      <rect x="15" y="15" width="10" height="10" rx="5" fill="#AFF704" />
                    </svg>
                    <div className="content">
                      <h3>Real-time Market Updates</h3>
                      <div className="section-detail">Stay ahead of the curve with live market feeds and real-time updates. Receive instant notifications about price changes, news, and trends, enabling you to make timely, informed decisions.</div>
                    </div>
                  </li>
                  <li>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="40" height="40" rx="20" fill="#242424" />
                      <rect x="15" y="15" width="10" height="10" rx="5" fill="#AFF704" />
                    </svg>
                    <div className="content">
                      <h3>User-Friendly Interface</h3>
                      <div className="section-detail">Enjoy a sleek and intuitive interface designed for effortless navigation. Towergear's mobile app ensures a seamless user experience, making trading accessible for both beginners and seasoned investors.</div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col md={6} xs={24}>
                <img src={mobile} alt="mobile" />
              </Col>
              <Col md={9} xs={24}>
                <ul className='list-left'>
                  <li>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="40" height="40" rx="20" fill="#242424" />
                      <rect x="15" y="15" width="10" height="10" rx="5" fill="#AFF704" />
                    </svg>
                    <div className="content">
                      <h3>Secure Transactions</h3>
                      <div className="section-detail"> Your security is our priority. Benefit from advanced encryption and multi-layered security protocols. Towergear's mobile app guarantees safe and secure transactions, giving you peace of mind while you trade.</div>
                    </div>
                  </li>
                  <li>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="40" height="40" rx="20" fill="#242424" />
                      <rect x="15" y="15" width="10" height="10" rx="5" fill="#AFF704" />
                    </svg>
                    <div className="content">
                      <h3>Portfolio Management:</h3>
                      <div className="section-detail">Monitor and manage your investments on the go. Track your portfolio's performance, view past transactions, and analyze trends, all from the palm of your hand. Stay in control and make strategic decisions effortlessly.</div>
                    </div>
                  </li>
                  <li>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="40" height="40" rx="20" fill="#242424" />
                      <rect x="15" y="15" width="10" height="10" rx="5" fill="#AFF704" />
                    </svg>
                    <div className="content">
                      <h3>Customer Support at Your Fingertips</h3>
                      <div className="section-detail">Have questions or need assistance? Our support team is just a tap away. Access customer support directly through the app, ensuring that your concerns are addressed promptly, enhancing your overall trading experience.
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col>
                <div className="section-detail white text-center mt-5">
                  With Towergear's mobile app, you're not just trading; you're trading smart. Enjoy the freedom of managing your investments from your mobile device and take charge of your financial future. Download the app today and step into a world of convenient, secure, and profitable trading.
                </div>
              </Col>
            </Row>
          </div >
        </div >
        <Footer />
      </div>
    </>
  )
}

export default MobileApp