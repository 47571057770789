import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Button,
  message,
  Space,
  Typography,
} from "antd";
import { NavLink, useNavigate } from "react-router-dom";


const KycCong = () => {
  const { Text, Link, Title } = Typography;
  useEffect(()=>{

    document.title= "KYC Approved"
  },[])

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col md={18} offset={3} xs={24}>
          <Card title="KYC-Approved" bordered={true}>
            <Title level={5} style={{ margin: "0px" }}>
            Congratulations! You are a verified member of Towergear Capitals.
              You can now use Towergear resources to reach your financial goals.
            </Title>
            <div style={{ textAlign: "end" }}>
              <Button
                type="primary"
                style={{
                  cursor: "not-allowed",
                }}
              >
                <span style={{ color: "green" }}>KYC Approved</span>
              </Button>

              <NavLink
                to="/member/kyc/user-details"
                style={{ padding: "20px" }}
              >
                <Button style={{ marginTop: "2%", width: "10%" }}>View</Button>
              </NavLink>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KycCong;
