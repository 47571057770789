import React, { useState, useEffect } from "react";
import { Col, Row, Spin } from "antd";

import axios from "axios";
import config from "../../../Config";

import promotion1 from "../../../assets/images/dashboard/promotion-banner.jpg";

import ProfitEarned from "./ProfitEarned";
import Overview from "./Overview";
import RefLink from "./RefLink";
import RecentRef from "./RecentRef";
import NetworkCard from "./NetworkCard";
import TradingStream from "./TradingStream";
import LoginCheck from "../shared/LoginCheck";
import TowerMillionaire from "./TowerMillionaire";
import FutureBox from "./FutureBox";
import RecentParticipants from "./RecentParticipants";


function Dashboard(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [DashboardData, setDashboardData] = useState({});

  const [RecentReferrals, setRecentReferrals] = useState([]);

  // loadings
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Towergear Dashboard";

    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetDashboardData",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {
        console.log("response", response.data);

        if (response.data.status_code == 1) {
          setDashboardData(response.data);
          setRecentReferrals(response.data.RecentReferrals);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <LoginCheck />
      <Row gutter={[24, 24]}>
      <Col md={8} xs={24}>
          <ProfitEarned
            roi={DashboardData.SelfROI}
            value={DashboardData.persecondValue}
            loading={loading}
            tradevolume={DashboardData.Investment_Balance}
            tradeincome={DashboardData.Profit_Received}
            compounding={DashboardData.Compounding}
          />
        </Col>
        <Col md={16} xs={24}>
          <Overview data={DashboardData} />
        </Col>
        
      </Row>
      <Row gutter={[24, 24]} style={{ marginTop: "30px" }}>
        <Col md={10} xs={24}>
          <RecentRef loading={loading} members={RecentReferrals} />
        </Col>
        <Col md={6} xs={24}>
          <NetworkCard count={DashboardData.TeamCount} />
        </Col>
        <Col md={8} xs={24}>
          <RefLink loading={loading} accountkey={DashboardData.AccountKey} />
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ marginTop: "30px" }}>
        <Col md={16} xs={24}>
          <img style={{ width: "100%" }} src={promotion1} />
        </Col>
        <Col md={8} xs={24}>
          <TradingStream
            total={DashboardData.TotalTrading}
            level1={DashboardData.Level1Trading}
            level2={DashboardData.Level2Trading}
            level3={DashboardData.Level3Trading}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col md={16} xs={24}>
          <TowerMillionaire
            loading={loading}
            EndDate={DashboardData.LuckyBoxEndDate}
            participationStatus={DashboardData.ParticipationStatus}
          />
        </Col>
        <Col md={8} xs={24}>
          <FutureBox
            Deduction_Box_Value={DashboardData.LuckyBoxDeductions}
            Future_Box_Value={DashboardData.LuckyBoxValue}
            LuckyBoxEndDate={DashboardData.LuckyBoxEndDate}
          />
       
          
        </Col>
      </Row>


      <RecentParticipants data={DashboardData.RecentLuckyBoxList} loading={loading} />
    </>
  );
}

export default Dashboard;
