import React, { useState } from "react";

import Logo from "../../assets/images/logo/logo.svg";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function TowergadHeader({ opened, closed, openSide }) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [AdminFull_Name, setAdminFull_Name] = useState(localStorage.getItem("AdminFull_Name"));

    let navigate = useNavigate();
    const handleSignOut = () => {
        localStorage.clear();
        navigate("/towergad/login");
    }

    const [active, setActive] = useState(false);

    const handleClick = () => {

        var admin_side_bar = document.getElementById("admin_side_bar");
        var overlay = document.getElementById("overlaymenu");
        var menubutton = document.getElementById("menubutton");
        var body = document.getElementById("body");


        if (overlay.classList.length === 0 && !menubutton.classList.contains('active')) {
            setActive(true);
            admin_side_bar.classList.add('active');
            overlay.classList.add('active');
            body.classList.add('overflow-hidden');
        } else {
            setActive(false);
            admin_side_bar.classList.remove('active');
            overlay.classList.remove('active');
            body.classList.remove('overflow-hidden');

        }

        document.getElementById("menubutton").addEventListener("click", closeMenu);

    };
    
    function closeMenu() {
        setActive(!true);

    }

    return (
        <>
            <Navbar className="header-admin d-md-block d-none" expand="lg" >
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/towergad/dashboard">
                        <img className="d-md-block d-none" src={Logo} />
                        {/* <i class="ri-align-justify ms-3 " style={{fontSize:"26px",cursor:"pointer"}} onClick={()=>{openSide? closed():opened()}} ></i> */}
                    </NavLink>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className="admin_logged_in ms-auto">
                            <ul className="admin_header_right">
                                <li>
                                    <a className="dd_menu_item" href="#" onClick={handleSignOut}>
                                        <span>Sign Out</span>
                                    </a>
                                </li>
                                <li>
                                    <div className="admin_logged_in_user">
                                        <span>IW</span>
                                        <div>
                                            <h4>{AdminFull_Name}</h4>
                                            <p>Admin</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            <Navbar className="admin-navbar">
                <div className="menu">
                    <svg
                        id="menubutton"
                        className={`ham hamRotate ham4 ${active ? 'active' : ''}`}
                        onClick={handleClick}
                        viewBox="0 0 100 100" width="80" onclick="this.classList.toggle('active')">
                        <path
                            class="line top"
                            d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
                        <path
                            class="line middle"
                            d="m 70,50 h -40" />
                        <path
                            class="line bottom"
                            d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
                    </svg>
                </div>
                <div className="user_id">
                    <div className="admin_logged_in_user">
                        <span>IW</span>
                        <div>
                            <h4>{AdminFull_Name}</h4>
                            <p>Admin</p>
                        </div>
                    </div>
                </div>
            </Navbar>
        </>
    );
}

export default TowergadHeader;